import { productApi } from './api';

const products = () => {
  const param = {
    consumer_key: process.env.GATSBY_WORDPRESS_CONSUMER_KEY,
    consumer_secret: process.env.GATSBY_WORDPRESS_CONSUMER_SECRET,
    status: 'publish',
    popularity: 'popularity',
    order: 'asc',
    orderby: 'popularity',
  };
  return productApi.get(`/products`, {
    params: param,
  });
};

const categories = () => {
  return productApi.get(`/products/categories`, {
    params: {
      consumer_key: process.env.GATSBY_WORDPRESS_CONSUMER_KEY,
      consumer_secret: process.env.GATSBY_WORDPRESS_CONSUMER_SECRET,
    },
  });
};

const productsByTag = async tagName => {
  const tags = await productApi.get(`/products/tags`, {
    params: {
      search: tagName,
      consumer_key: process.env.GATSBY_WORDPRESS_CONSUMER_KEY,
      consumer_secret: process.env.GATSBY_WORDPRESS_CONSUMER_SECRET,
    },
  });

  if (tags.data.length === 0) {
    return tags;
  }

  const [tag] = tags.data;

  const param = {
    consumer_key: process.env.GATSBY_WORDPRESS_CONSUMER_KEY,
    consumer_secret: process.env.GATSBY_WORDPRESS_CONSUMER_SECRET,
    status: 'publish',
    popularity: 'popularity',
    order: 'asc',
    orderby: 'popularity',
    tag: tag.id,
  };

  const productFiltered = await productApi.get(`/products`, {
    params: param,
  });

  return productFiltered;
};

export default {
  products,
  categories,
  productsByTag,
};
